import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .react-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    max-height: 670px;
    max-width: 670px;
    background-color: #255c99;
    padding: 3rem 0 0 0;
    position: relative;
    border-radius: 0.25rem;
    border: 1px solid #000;
  }

  .react-modal-close {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    border: 0;
    background-color: transparent;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
