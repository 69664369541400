import { PageContent } from "@havan/react-components";
import React from "react";

import { Container } from "./styles";

export const Dashboard: React.FC = () => {
  return (
    <PageContent title="Dashboard">
      <Container>
        <p>Bem-vindo</p>
      </Container>
    </PageContent>
  );
};
