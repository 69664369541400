import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background-color: rgb(217, 217, 217);
  width: 100%;
  padding: 5px 10px;
`;
