import noContent from "../../../assets/notData.svg";

import { Container, TitleNoData } from './styles';

function NotData() {
  return (
    <Container>
      <img alt="no-content" src={noContent} />
      <TitleNoData>Nenhum sorteio realizado</TitleNoData>
    </Container>
  );
}

export default NotData;
