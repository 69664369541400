import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  width: 355px;
  height: 355px;
`;

