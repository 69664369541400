import React, { ReactNode } from "react";
import { Button } from "./styles";

interface LoadingButtonProps {
  carregando?: boolean;
  children?: ReactNode;
  onClick: Function;
  className?: string;
  title?: string;
  styleButton?: string;
  iconClass?: string;
}

export const LoadingButton = ({ carregando, children, onClick, className, title, styleButton, iconClass }: LoadingButtonProps) => {
  return (
    <Button
      type="button"
      className={'btn ' + (styleButton ? styleButton : 'btn-primary') + ' ' + className}
      disabled={carregando}
      onClick={() => onClick()}
      title={title}
    >
      <i className="fas fa-circle-notch fa-spin" hidden={!carregando}></i>
      {iconClass && (
        <i className={iconClass} hidden={carregando}></i>
      )}
      {children}
    </Button>
  );
};
