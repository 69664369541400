import { PageContent } from "@havan/react-components";
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import { criarNome } from "../../../helpers/util";

import { IAtendimentoCrmJuridico } from "../../../class/IAtendimentoCrmJuridico";
import { IApiResponse } from "../../../class/IApiResponse";
import { CardAtendimento } from "../../../components/CardAtendimento";
import { LoadingButton } from "../../../components/LoadingButton";
import { ZoomModal } from "../../../components/ZoomModal";

import { Container, Anotacao, Image, ImageContainer } from "./styles";
import "react-toastify/dist/ReactToastify.min.css";
import EncantadaGif from "../../../assets/encantada.gif";

interface JuridicoParams {
  protocolo?: string;
}

export const Juridico: React.FC = () => {
  const OCORRENCIA_JURIDICO_URL =
    "atendimento-crm-juridico/obter-ocorrencia-juridico/";
  const ANEXO_URL = `${process.env.REACT_APP_URL}anexos/obter-anexo-crm/`;
  const ANEXO_SOL_TROCA_URL = `${process.env.REACT_APP_URL}anexos/obter-anexo-trocas/`;

  const { user } = useAuth();
  const { protocolo } = useParams() as JuridicoParams;
  const history = useHistory();

  const [carregando, setCarregando] = useState(true);
  const [atendimento, setAtendimento] = useState<IAtendimentoCrmJuridico>(
    {} as IAtendimentoCrmJuridico
  );
  const [novaAnotacao, setNovaAnotacao] = useState("");
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [isAtendimentoFinalizado, setIsAtendimentoFinalizado] = useState(false);
  const [imgZoomUrl, setImgZoomUrl] = useState("");
  const [anexosLocalUrl, setAnexosLocalUrl] = useState<string[]>([]);
  const [solicitacaoTrocaAnexosLocalUrl, setSolicitacaoTrocaAnexosLocalUrl] =
    useState<string[]>([]);

  function handleCloseZoomModal() {
    setIsZoomModalOpen(false);
  }

  const carregarDados = useCallback(async () => {
    await api
      .get(`${OCORRENCIA_JURIDICO_URL}${protocolo}`)
      .then(async (atendimento) => {
        setAtendimento(atendimento.data);
        setCarregando(false);

        if (
          atendimento.data.atendimentoCRMSituacao.nome === "Finalizado" ||
          atendimento.data.emAtendimento === false
        ) {
          history.push(`/transferencia-concluida`);
        }

        if (atendimento.data.anexos?.atendimentoCRMAnexos?.length > 0) {
          let anexosUrls: string[] = [];

          for (const anexo of atendimento.data.anexos.atendimentoCRMAnexos) {
            let response = await fetch(
              `${ANEXO_URL}${anexo.idImagemFileStream}`,
              {
                headers: new Headers({
                  Authorization:
                    "Bearer " + localStorage.getItem("@HavanLabs:token"),
                }),
              }
            );

            let image = await response.blob();

            anexosUrls.push(URL.createObjectURL(image));
          }

          setAnexosLocalUrl(anexosUrls);
        }

        if (atendimento.data.anexos?.solicitacaoTrocaAnexos?.length > 0) {
          let anexosUrls: string[] = [];

          for (const anexo of atendimento.data.anexos.solicitacaoTrocaAnexos) {
            let response = await fetch(
              `${ANEXO_SOL_TROCA_URL}${anexo.idImagemFileStream}`,
              {
                headers: new Headers({
                  Authorization:
                    "Bearer " + localStorage.getItem("@HavanLabs:token"),
                }),
              }
            );

            let image = await response.blob();

            anexosUrls.push(URL.createObjectURL(image));
          }

          setSolicitacaoTrocaAnexosLocalUrl(anexosUrls);
        }
      });
  }, [protocolo, ANEXO_URL, ANEXO_SOL_TROCA_URL, history]);

  useEffect(() => {
    carregarDados();
  }, [carregarDados]);

  const handleChangeNovaAnotacao = useCallback((event) => {
    setNovaAnotacao(event.target.value);
  }, []);

  const zoom = useCallback((url: string) => {
    setImgZoomUrl(url);
    setIsZoomModalOpen(true);
  }, []);

  const salvarAnexo = useCallback((url: string) => {
    let nomeArquivo = criarNome(20);

    fetch(url).then(async (resp) => {
      const blob = await resp.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${nomeArquivo}.jpg`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    });
  }, []);

  const gravarNovaAnotacaoJuridico = useCallback(async () => {
    try {
      setCarregando(true);

      if (novaAnotacao.trim() === "") {
        toast.error("Digite sua anotação no campo 'Nova Anotação'");
        setCarregando(false);
        return;
      }
      if (novaAnotacao.length > 3072) {
        toast.error(
          "A anotação não pode ultrapassar o tamanho de 3072 caracteres"
        );
        setCarregando(false);
        return;
      }

      let anotacao = {
        idAtendimentoCRM: atendimento.id,
        textoAnotacao: novaAnotacao,
      };

      let { data }: { data: IApiResponse } = await api.post(
        "atendimento-crm-juridico/gravar-anotacao-juridico",
        anotacao
      );

      if (!data.sucesso) {
        toast.error(data.message);
        setCarregando(false);
        return;
      }

      await carregarDados();

      toast.success("Anotação cadastrada com sucesso.");
      setNovaAnotacao("");
      setCarregando(false);
    } finally {
      setCarregando(false);
    }
  }, [atendimento.id, carregarDados, novaAnotacao]);

  const concluirTransferenciaJuridico = useCallback(async () => {
    try {
      setCarregando(true);
      let concluirAtendimento = {
        idAtendimentoCRM: atendimento.id,
      };

      let { data }: { data: IApiResponse } = await api.post(
        "atendimento-crm-juridico/concluir-atendimento-juridico",
        concluirAtendimento
      );

      if (!data.sucesso) {
        toast.error(data.message);
        setCarregando(false);
        return;
      }
      setIsAtendimentoFinalizado(true);
      toast.success("Atendimento Concluído com Sucesso");
      setNovaAnotacao("");
      setCarregando(false);
    } finally {
      setCarregando(false);
    }
  }, [carregarDados, atendimento]);

  return (
    <PageContent title="AtendimentoCRM - Jurídico">
      <Container>
        {!isAtendimentoFinalizado ? (
          <>
            <ul
              className="nav nav-tabs"
              id="atendimento-crm-juridico-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="hist-jur-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#histJur"
                  type="button"
                  role="tab"
                  aria-controls="histJur"
                  aria-selected="true"
                >
                  Histórico Jurídico
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="anexos-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#anexos"
                  type="button"
                  role="tab"
                  aria-controls="anexos"
                  aria-selected="false"
                >
                  Anexos
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="histJur"
                role="tabpanel"
                aria-labelledby="hist-jur-tab"
              >
                <CardAtendimento atendimento={atendimento} />

                {atendimento?.anotacoesJuridico?.map((anotacao) => (
                  <Anotacao
                    key={anotacao.id}
                    className={
                      anotacao.usuario.codigo === user.codigo
                        ? "anotacaoJuridico"
                        : "anotacaoAtendente"
                    }
                  >
                    <div className="data-tipo">
                      <span>
                        <strong>Data: </strong>
                        {anotacao.dataFormatada}
                      </span>
                    </div>
                    <span className="atendente">
                      <span>
                        <strong>Usuario: </strong>
                        {anotacao.usuario.codigoNome}
                      </span>
                    </span>
                    <div className="descricaoCrm">{anotacao.descricao}</div>
                  </Anotacao>
                ))}

                {atendimento?.atendimentoCRMSituacao?.nome !== "Finalizado" && (
                  <div className="novaAnotacao">
                    <label htmlFor="novaAnotacao">Nova anotação</label>
                    <textarea
                      id="novaAnotacao"
                      name="novaAnotacao"
                      value={novaAnotacao}
                      onChange={(event) => handleChangeNovaAnotacao(event)}
                    ></textarea>
                    <LoadingButton
                      carregando={carregando}
                      onClick={() => gravarNovaAnotacaoJuridico()}
                      title="Gravar anotação"
                    >
                      Salvar Anotação
                    </LoadingButton>
                  </div>
                )}
              </div>

              <div className="botaoConcluir">
                <LoadingButton
                  carregando={carregando}
                  onClick={() => concluirTransferenciaJuridico()}
                  title="Concluir Transferência"
                  styleButton="btn-danger"
                >
                  Concluir Transferência
                </LoadingButton>
              </div>

              <div
                className="tab-pane fade secaoLista"
                id="anexos"
                role="tabpanel"
                aria-labelledby="anexos-tab"
              >
                <p className="titulo-anexo">Anexos do Atendimento</p>

                <div className="secaoAnexos">
                  {anexosLocalUrl.map((anexoUrl, index) => (
                    <div key={index}>
                      <div className="anexo">
                        <img
                          src={anexoUrl}
                          alt="Anexo"
                          onClick={() => zoom(anexoUrl)}
                        />
                        <LoadingButton
                          className="btnAnexo salvarBtn"
                          iconClass="fas fa-download"
                          carregando={carregando}
                          onClick={() => salvarAnexo(anexoUrl)}
                          title="Baixar imagem"
                        ></LoadingButton>
                      </div>
                    </div>
                  ))}
                </div>

                {solicitacaoTrocaAnexosLocalUrl.length > 0 && (
                  <div className="separador-anexo">
                    <p className="titulo-anexo">
                      Anexos da Solicitação de troca
                    </p>
                    <div className="secaoAnexos">
                      {solicitacaoTrocaAnexosLocalUrl.map((anexoUrl, index) => (
                        <div key={index}>
                          <div className="anexo">
                            <img
                              src={anexoUrl}
                              alt="Anexo"
                              onClick={() => zoom(anexoUrl)}
                            />
                            <LoadingButton
                              className="btnAnexo salvarBtn"
                              iconClass="fas fa-download"
                              carregando={carregando}
                              onClick={() => salvarAnexo(anexoUrl)}
                              title="Baixar imagem"
                            ></LoadingButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <ImageContainer>
            <h1>Transferência finalizada com sucesso</h1>
            <Image src={EncantadaGif} />
          </ImageContainer>
        )}
      </Container>

      <ZoomModal
        isOpen={isZoomModalOpen}
        onRequestClose={handleCloseZoomModal}
        imgUrl={imgZoomUrl}
        imgAlt="Anexo"
      />
    </PageContent>
  );
};
