import React from "react";
import { PageContent } from "@havan/react-components";

import { Container, Image } from "./styles";
import EncantadaGif from "../../assets/encantada.gif";

export const NotFoundPage: React.FC = () => {
  return (
    <PageContent title={"NotFound"}>
      <Container>
        <h1>Transferência já foi finalizada</h1>
        <Image src={EncantadaGif} />
      </Container>
    </PageContent>
  );
};
