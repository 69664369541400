import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

export const SignIn: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const { signIn } = useAuth();

  const token = query.get("access_token");

  let urlPath = localStorage.getItem("@HavanLabs:redirectUrl") || '';

  if (!token) {
    const webUrl = process.env.REACT_APP_WEB;
    const authUrl = process.env.REACT_APP_AUTH_URL;

    window.location.href = `${authUrl}/AutenticacaoApi/Entrar?ReturnUrl=${webUrl}/&RedirectUrl=${webUrl}${urlPath}`;
    return null;
  }

  signIn(token);
  window.location.href = urlPath;
  return null;
};
