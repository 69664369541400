import {
  Footer,
  Navbar,
  PageBody,
  Profile,
  Sidebar,
  ThemeProvider,
} from "@havan/react-components";
import { BrowserRouter } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { ToastContainer } from "react-toastify";

import { useAuth } from "./hooks/auth";
import Routes from "./routes";

import { GlobalStyle } from "./styles/global";
import { ThemeProvider as ThemeMaterialUI, createTheme } from "@mui/material";
import { ptBR } from "@mui/material/locale";

function App() {
  const { signOut, user } = useAuth();

  if (!user) {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }

  const theme = createTheme(
    {
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "#e6e6e6",
              },
              backgroundColor: "#FFFFFF",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: "#5F86BE",
            },
          },
        },
      },
    },
    ptBR
  );

  return (
    <BrowserRouter>
    <ThemeMaterialUI theme={theme}>
      <ThemeProvider>
        <GlobalStyle />
        <PageBody>
          <Navbar signOut={signOut}>
            <Profile user={user}>
              <button type="button" title="Sair do sistema" onClick={signOut}>
                <FaSignOutAlt /> Logout
              </button>
            </Profile>
          </Navbar>
          <Sidebar
            list={[
              { id: 1, label: "Dashboard", to: "/dashboard" },
              { id: 2, label: "Sorteador", to: "/sorteador" }
            ]}
          />
          <Routes />
          <Footer />

          <ToastContainer />
        </PageBody>
      </ThemeProvider>
      </ThemeMaterialUI>
    </BrowserRouter>
  );
}

export default App;
