import { Box, Button, Typography, Modal, Backdrop, Fade } from "@mui/material";
import { Wrapper } from "./styles";
import Lottie from 'react-lottie';
import diceAnimation from '../../../assets/diceAnimation.json'

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #ffff",
  boxShadow: 24,
  p: 4,
};

interface IPropsModalNotAuthorization {
  openModal: boolean;
  setOpenModal: (args: any) => void;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: diceAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function ModalNotAuthorization({
  openModal,
  setOpenModal,
}: IPropsModalNotAuthorization) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={setOpenModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Wrapper>
              <Lottie 
                options={defaultOptions}
                height={400}
                width={400} />
            </Wrapper>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalNotAuthorization;
