import React from "react";
import Modal from 'react-modal';

import { Container } from "./styles";

interface ZoomModalProps {
  isOpen: boolean,
  onRequestClose: () => void,
  imgUrl: string,
  imgAlt: string
}

Modal.setAppElement('#root')

export function ZoomModal({ isOpen, onRequestClose, imgUrl, imgAlt }: ZoomModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <i className="fas fa-times" style={{color: "white"}}/>
      </button>

      <Container>
        <img src={imgUrl} alt={imgAlt} />
      </Container>
    </Modal>
  );
};
