import styled from "styled-components";

export const Container = styled.div`
  button.nav-link.active {
    background-color: #f2f2f2;
  }

  .secaoLista {
    width: 100%;
    padding: 0;
    overflow-y: auto;
    margin: 10px 0;
  }

  .novaAnotacao {
    margin-top: 20px;

    textarea {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      height: 90px;
    }
  }

  .botaoConcluir{
    margin-top: 20px;
  }

  /* ANEXOS */
  .separador-anexo {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 3px solid #ddd;
  }

  .titulo-anexo {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
  }

  .separador-anexo:nth-of-type(1) {
    margin-top: 0;
  }

  .secaoAnexos {
    max-height: 400px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .anexo {
      width: 230px;
      height: 230px;
      position: relative;
      cursor: zoom-in;

      img {
        width: 100%;
        height: 100%;
      }

      .btnAnexo {
        position: absolute;
        right: -7px;
        height: 35px;
        width: 35px;
        padding: 0;

        i {
          margin: 0;
        }
      }

      /* .deletarBtn {
        top: 35px;
      } */
    }
  }
`;

export const Anotacao = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  width: 100%;
  padding: 5px 10px;
  margin-top: 10px;
  min-height: 85px;

  &.anotacaoJuridico, &.anotacaoPrivada {
    background-color: #bdd7ee;
  }

  &.anotacaoAtendente {
    background-color:#dde6d3;
  }

  .data-tipo {
    display: flex;
    justify-content: space-between;
  }

  .atendente {
    margin-bottom: 20px;
  }

  .descricaoCrm {
    white-space: pre-line;
  }
`;

export const Image = styled.img`
  width: 355px;
  height: 355px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`
