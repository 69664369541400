import { useRef, useState } from "react";
import { PageContent } from "@havan/react-components"
import { Box, Button, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import CasinoIcon from '@mui/icons-material/Casino';
import { ContainerFilter, TitleTable } from "./style"
import api from "../../services/api";
import { toast } from "react-toastify";
import NotData from "./NotData";
import LoadingModal from './LoadingModal';

interface IPropsSorteado {
    name: string
    numberDrawn: number,
    userCode: string,
    filial: string,
    regiao?: string
}

export const Sorteador: React.FC = () => {

    const [drawn, setDrawn] = useState<IPropsSorteado[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [sorted, setSorted] = useState(false);
    const howMuchDraw = useRef<any>();
    const [allRegions, setAllRegions] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAllRegions(event.target.value as string);
    };

    const handleSort = async () => {

        // if (howMuchDraw.current.value == "") {
        //     toast.error('É necessário selecionar a quantidade de sorteados', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return;
        // }

        // let value = Number.parseInt(howMuchDraw.current.value);

        // if (value <= 0) {
        //     toast.error('Não é possivel sortear menor que 0', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return;
        // }

        // if (value > 30) {
        //     toast.error('O limite máximo é de até 10 sorteados', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return;
        // }

        // if (value >= 1 && value <= 30) {
        setSorted(true);
        setDrawn([]);
        setOpenModal(true);
        if(allRegions == '1')
            getRaffleList(true);
        else
            getRaffleList(false);
        //}
    }


    async function getRaffleList(/*number: number*/allRegion: boolean) {
        // try {
        //     const { data } = await api.get(`Raffle/list?numberCandidates=${number}`);
        //     setDrawn(data);
        // }
        // catch (error) {
        //     console.log(error)
        // }
        // finally {
        //     setTimeout(() => {
        //         setOpenModal(false);
        //     }, 1000)
        // }
        try {
            if(allRegions){
                const { data } = await api.get(`Raffle/true`);
                setDrawn(data);
            }
            else {
                const { data } = await api.get(`Raffle/false`);
                setDrawn(data);
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setTimeout(() => {
                setOpenModal(false);
            }, 1000)
        }
    }

    return (
        <PageContent title="Sorteador Liberdade">
            <ContainerFilter>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 1, width: "25ch" },
                        maxWidth: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <TextField
                        style={{ width: '15%' }}
                        type="number"
                        className="simple-textfield"
                        id="outlined-basic"
                        label="Sortear"
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                        size="small"
                        inputRef={howMuchDraw}
                        defaultValue="" />

                    <TextField
                        disabled
                        style={{ width: '43%' }}
                        className="simple-textfield"
                        id="outlined-basic"
                        label="Campanha"
                        variant="outlined"
                        size="small"
                        defaultValue="Quiz Liberdade" />

                    <Select
                        style={{ width: '15%', height: '5%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={allRegions}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>Sim</MenuItem>
                        <MenuItem value={0}>Não</MenuItem>
                    </Select>

                    <Button
                        variant="contained"
                        style={{ width: 180, marginBottom: 24, marginLeft: 8 }}
                        startIcon={<CasinoIcon />}
                        onClick={handleSort}
                    >
                        Sortear
                    </Button>
                </Box>
            </ContainerFilter>

            <TableContainer component={Paper}>
                {
                    (!sorted) ?
                        (<NotData />)
                        :
                        (
                            <>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table"
                                >

                                    <TableHead>
                                        <TableCell>
                                            <TitleTable>Sorteado N.º</TitleTable>
                                        </TableCell>
                                        <TableCell>
                                            <TitleTable>Codigo</TitleTable>
                                        </TableCell>
                                        <TableCell>
                                            <TitleTable>Nome</TitleTable>
                                        </TableCell>
                                        <TableCell>
                                            <TitleTable>Filial</TitleTable>
                                        </TableCell>
                                        <TableCell>
                                            <TitleTable>Região</TitleTable>
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {drawn?.map((row) => (
                                            <TableRow
                                                key={row?.numberDrawn}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell>{row?.numberDrawn}</TableCell>
                                                <TableCell>{row?.userCode}</TableCell>
                                                <TableCell>{row?.name}</TableCell>
                                                <TableCell>{row?.filial}</TableCell>
                                                <TableCell>{row?.regiao}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        )
                }

            </TableContainer>
            <LoadingModal
                openModal={openModal}
                setOpenModal={() => setOpenModal(false)}
            />
        </PageContent>
    )
}