import React from "react";
import { IAtendimentoCrmJuridico } from "../../class/IAtendimentoCrmJuridico";
import { Container } from "./styles";

interface CardAtendimentoProps {
  atendimento: IAtendimentoCrmJuridico
}

export const CardAtendimento = ({ atendimento }: CardAtendimentoProps) => {
  return (
    <Container>
      <span><strong>Cliente: </strong>{ atendimento.cliente?.nome }</span>
      <span><strong>Telefone: </strong>{ atendimento.cliente?.numeroCelularCompleto }</span>
      <span><strong>E-mail: </strong>{ atendimento.cliente?.email }</span>
      <span><strong>Localizador: </strong>{ atendimento.protocolo}</span>
      <span><strong>Transportadora: </strong>{ atendimento.codNomeTransportadora }</span>
    </Container>
  );
};
